.button.is-rounded {
    border-radius: 290486px;
}

.field.has-addons .control:first-child .is-rounded {
    border-bottom-left-radius: 290486px;
    border-top-left-radius: 290486px;
}

.field.has-addons .control:last-child .is-rounded {
    border-bottom-right-radius: 290486px;
    border-top-right-radius: 290486px;
}

.video-controls {
    transition: all 0.2s ease;
}

.player-buttons {
    /* background: url('http://cdn1.iconfinder.com/data/icons/iconslandplayer/PNG/64x64/CircleBlue/Play1Pressed.png') center center no-repeat;
    height: 128px; */
    left: 50%;
    margin: -1px 0 0 -64px;
    position: absolute;
    /* top: 36%; */
    margin-top: -4%;
    width: 128px;
    z-index: 1;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Upload Video';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
    margin-left: 35%;
}

.custom-file-input:hover::before {
    border-color: black;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* .ivod-video {
    width: 50% !important;
    height: calc(var(--main-width) * var(--aspect-ratio));
} */

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
    /* Styles */
    .player-buttons {
        top: 85% !important;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 886) {
    /* Styles */
    .player-buttons {
        top: 80% !important;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    /* Styles */
    .player-buttons {
        top: 85% !important;
    }
}

@media only screen and (min-device-width: 410px) and (max-device-width: 730px) {
    /* Styles */
    .player-buttons {
        top: 85% !important;
    }
}

@media only screen and (min-device-width: 410px) and (max-device-width: 830px) {
    /* Styles */
    .player-buttons {
        top: 85% !important;
    }
}

/* iPhone 4 ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
    .player-buttons {
        top: 85% !important;
    }
}

/* iPhone 6, 7, 8 ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    .player-buttons {
        top: 75% !important;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    .player-buttons {
        top: 75% !important;
    }
}

/* iPhone 6+, 7+, 8+ ----------- */

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    .player-buttons {
        top: 85% !important;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    .player-buttons {
        top: 85% !important;
    }
}

/* iPhone X ----------- */

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
    .player-buttons {
        top: 75% !important;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
    .player-buttons {
        top: 68% !important;
    }
}

/* Smartphones (landscape) ----------- */

@media only screen and (min-width: 321px) {
    /* Styles */
}

/* Smartphones (portrait) ----------- */

@media only screen and (max-width: 320px) {
    /* Styles */
}

/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Styles */
}

@media only screen and (-webkit-min-device-pixel-ratio: 4) {
    /* CSS */
    .player-buttons {
        top: 95% !important;
    }
}

.delete-button {
    background: #FFFFFF;
    border: 1px solid #185F6F;
    box-sizing: border-box;
    border-radius: 4px;
    color: #185F6F;
    margin: 0px 20px !important;
    font-family: Montserrat;
    font-style: normal;
}

.post-button {
    background: #185F6F;
    border: 1px solid #185F6F;
    box-sizing: border-box;
    border-radius: 4px;
    color: #ffffff;
    font-family: Montserrat;
    font-style: normal;
    margin: 0px 20px !important;
}

.ivod-box {
    background: linear-gradient(99.33deg, #22879E 7.23%, #63A2AC 17.84%, #8FB4B5 27%, #ACC0BB 35.39%, #CECFC3 42.48%, #DDD5C6 46.74%, #EEDCC9 52.33%, #F8E0CB 56.56%, #FFE3CD 62.11%, #FDE0C9 68.44%, #F4D3BA 75.77%, #D3A47F 92.94%) !important;
    border-radius: 4px 4px 0px 0px !important;
    height: 5% !important;
}

.login-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}

.ivod-login-button {
    background: #185F6F;
    color: #ffffff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.forgot-password-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #185F6F;
}

.ivod-splash {
    background: rgb(125, 170, 176);
    background: linear-gradient(180deg, rgba(125, 170, 176, 1) 0%, rgba(250, 224, 203, 1) 100%, rgba(250, 225, 203, 1) 100%);
    height: 100% !important;
}

.splash-image {
    height: 800px !important;
    width: 500px;
}

.navbar-burger {
    margin-left: 0px !important;
    color: #185F6F;
}

.menu {
    background-color: #185F6F;
    color: #fff;
    height: 950px !important;
}

.profile-edit {
    color: #185F6F;
}

.card-footer-item {
    border: none !important;
}

.like-container {
    /* margin-left: 50%; */
}

.second-card-footer {
    border-top: none;
}

 .ant-pagination .ant-pagination-next  button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-pagination .ant-pagination-prev button {
    display: flex;
    align-items: center;
    justify-content: center;
} 